import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { graphql, Link } from 'gatsby'

export const query = graphql`
query EngApplyPageQuery {
  heroImg: file(relativePath: {eq: "eng-apply-hero.svg"}) {
    publicURL
  }
 }
`

const JoinUsPage = ({ data, errors, navigate }) => {
  useEffect(() => {
    navigate('/404')
  }, [])
  return null

  const hCaptchaSiteKey = process.env.GATSBY_HCAPTCHA_SITE_KEY || '20000000-ffff-ffff-ffff-000000000002'
  const engApplyHost = process.env.GATSBY_ENG_APPLY_HOST || 'http://localhost:8001'

  const [hCaptchaToken, setHCaptchaToken] = useState(null)
  const captchaRef = useRef(null)
  const [email, setEmail] = useState(null)

  const [token, setToken] = useState(null)
  const [copied, setCopied] = useState(null)

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3 * 1000)
  }

  const getMyToken = () => {
    return window.fetch(`${engApplyHost}/api/v1/auth`, {
      method: 'POST',
      headers: {
        'X-H-CAPTCHA': hCaptchaToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then(resp => resp.json())
      .then((t) => setToken(t.token))
  }

  const onSubmit = (e) => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    if (!email) {
      return
    }
    setToken(null)
    e.preventDefault()
    e.stopPropagation()
    if (!hCaptchaToken) {
      if (!captchaRef.current) {
        return
      }
      captchaRef.current.execute()
      return
    }
    return getMyToken()
  }

  return (
    <main>
      <section className='w-full px-8 text-gray-700 bg-white shadow-md'>
        <div
          className='container flex flex-col flex-wrap items-center justify-between py-5 w-full mx-auto md:flex-row'
        >
          <div className='relative flex flex-col md:flex-row'>
            <Link
              to='/'
              className='flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0'
            >
              <span className='mx-auto text-xl font-black leading-none text-[#01579b] select-none'>Salzify</span>
            </Link>
          </div>
        </div>
      </section>
      <section className='container flex flex-col items-center lg:flex-row px-2 pt-32 md:px-0 mx-auto'>
        <div className='container items-center max-w-6xl px-5 space-y-6 text-center'>
          <h1
            className='text-4xl font-extrabold tracking-tight text-left text-gray-900 sm:text-5xl md:text-6xl md:text-center'
          >
            Thanks for your interest!
          </h1>
          <div className='relative flex flex-col justify-center md:flex-row md:space-x-4'>
            <p className='mt-2'>
              At the moment, we are seeking talent software engineers to join our team.
              If this doesn't fit you, please keep tuned on <a
              className='font-semibold whitespace-nowrap text-indigo-600 hover:text-indigo-700 mt-8'
              href='https://www.linkedin.com/company/salzify' target='_blank'>LinkedIn</a> for future
              opportunities.<br />
            </p>
          </div>
        </div>
        <div className='container items-center max-w-4xl px-5  mt-16 text-center'>
          <img src={data.heroImg.publicURL} />
        </div>
      </section>

      <section
        className='container flex flex-col justify-center lg:w-4/6 md:w-5/6 sm:w-full px-2 pt-32 md:px-0 mx-auto'>
        <div className='flex items-center sm:flex-row w-full mt-4'>
          <p className='font-semibold text-2xl'>
            Software Engineer
          </p>
        </div>
        <p className='mt-2'>
          <p className='mt-2'>
            At Salzify we are building a business intelligence solution that connects the dots in the wholesale retail
            workflows.
            We do this by using technology to interconnect different organization departments in the workflow boosting
            their speed and providing insights about the organization performance.
          </p>
          <p className='mt-2'>
            We are just getting started at an early stage. However, we had few early adopters running our MVP product on
            prod.
          </p>
          <p className='mt-2'>
            We are seeking candidates who are proactive, faster learner, and autonomous. They ask lots of questions and
            try to find the more suitable answers.
          </p>
          <p className='mt-2'>
            The ideal candidate will help us shape our product and take it to the next step.
            They will participate in designing and implementing high-quality software and great customer experience.
            They also will help building our team culture and values.
          </p>
          <p className='mt-2'>
            Our current tech stack is PostgreSQL, GoLang, and Angular. We are deploying a simple docker-compose on
            digitalocean.
          </p>

          <p className='mt-2 underline decoration-solid font-bold'>
            This posting targets full-stack, backend, or frontend engineers. Prior experience of current tech stack is a
            plus.
          </p>

          <p className='mt-4'>
            <strong>Responsibilities</strong>
            <ul className='list-disc list-inside'>
              <li>Analyze and maintain existing codebase</li>
              <li>Develop quality new features and incremental enhancements</li>
              <li>Design highly scalable, extendable code</li>
              <li>Participate in scrum iterations to deliver customer value faster</li>
              <li>Plan, design, and implement solutions to mitigate technical debts</li>
              <li>Share gained knowledge with teammates</li>
              <li>Contribute to internal knowledge base</li>
            </ul>
          </p>

          <p className='mt-2'>
            <strong>Qualifications</strong>
            <ul className='list-disc list-inside'>
              <li>Bachelor's degree or equivalent experience in Computer Science or related field</li>
              <li>Professional career experience with 2+ programming languages</li>
              <li>2+ years of professional experience</li>
              <li>Strong knowledge of designing, implementing, and consuming APIs</li>
              <li>Backend only: experience with SQL databases and can write queries outside the ORM.</li>
              <li>Plus: 5+ years of professional experience</li>
              <li>Plus: prior experience of our tech stack</li>
              <li>Plus: experience with containers, kubernetes, microservices, ... you know :D</li>
              <li>Plus: experience with cross-platform or native mobile development</li>
            </ul>
          </p>

          <p className='mt-2'>
            <strong>Benefits</strong>
            <ul className='list-disc list-inside'>
              <li>Competitive salaries</li>
              <li>Social and medical insurance</li>
              <li>Paid time-off and holidays</li>
              <li>Fully remote, hybrid, or on-office flexibility</li>
              <li>Flexible working hours</li>
            </ul>
          </p>

        </p>
      </section>

      <section
        className='container flex flex-col justify-center lg:w-4/6 md:w-5/6 sm:w-full px-2 md:px-0 mx-auto'>
        <div className='flex items-center sm:flex-row w-full mt-4'>
          <p className='font-semibold text-xl'>
            How to apply?
          </p>
        </div>
        <p className='mt-2'>
          We are trying to make the apply process fun as possible.
          We appreciate your interest and value your time. <br />
          The process is simple 2-step:
          <ol className='list-decimal list-inside'>
            <li>
              Apply using our Job Apply API. [30m]
            </li>
            <li>
              Single interview to chat about your experiences and how we can work together. [1h]
            </li>
          </ol>
        </p>
        <p className='font-semibold text-xl mt-4'>
          What is Job Apply API
        </p>
        <p className='mt-2'>
          It is the fun part! ;)
          Engineers are too lazy to update their CVs and profiles.
          So maybe challenging them is the best way to get their attention.<br />
          You can use the APIs to submit what you would submit in an email or through a hiring platform.<br />
        </p>
        <p className='mt-2'>
          The APIs are documented using <a
          className='font-semibold whitespace-nowrap text-indigo-600 hover:text-indigo-700 mt-8'
          href='https://swagger.io/specification/' target='_blank'>OAS 3</a>.
          Please find the hosted UI version below.
          You can also download the spec file and use your own tooling.
          There are no requirements on how to consume the APIs, please be productive as possible.
        </p>
        <div className='flex items-center sm:flex-row w-full mt-4'>
          <p className='font-semibold text-2xl'>
            Let's do it!!
          </p>
          <div className='flex flex-1 items-center justify-end'>
            <a
              href='https://stoplight.io/api/v1/projects/salzify/job-apply-api/nodes/openapi3.json'
              target='_blank'
              className='flex ml-3 px-4 py-2 mb-3 font-semibold text-sm text-white bg-purple-500 md:mb-0 hover:bg-purple-700 rounded-full'
            >
              Spec file
              <svg className='ml-3 h-4 w-4 text-white' viewBox='0 0 24 24' fill='none' stroke='currentColor'
                   strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                <path d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4' />
              </svg>

            </a>
            <a
              href='https://salzify.stoplight.io/docs/job-apply-api'
              target='_blank'
              className='flex ml-3 px-4 py-2 mb-3 font-semibold text-sm text-white bg-purple-500 md:mb-0 hover:bg-purple-700 rounded-full'
            >
              Job Apply API
              <svg className='ml-3 h-4 w-4 text-white' viewBox='0 0 24 24' fill='none' stroke='currentColor'
                   strokeWidth='2'
                   strokeLinecap='round' strokeLinejoin='round'>
                <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6' />
                <polyline points='15 3 21 3 21 9' />
                <line x1='10' y1='14' x2='21' y2='3' />
              </svg>
            </a>
          </div>
        </div>
        <p className='mt-4'>
          The API is protected by a JWT bearer authentication. Please use the form below to obtain a JWT token.
          Please note that the token expires in 1h. So maybe better to prepare your payloads before obtaining the token.
        </p>
      </section>

      <section className='container flex flex-col justify-center lg:w-2/6 md:w-3/6 sm:w-4/6 px-2 md:px-0 mx-auto'>
        <form id='token-form' className='form bg-white p-6 my-10 shadow-md' action='#' onSubmit={() => onSubmit()}>
          <h3 className='text-2xl text-gray-900 font-semibold'>Get your token!</h3>

          <div className='container'>
            <div className='flex justify-center pt-5'>
              <input type='email' name='email' required placeholder='Email*'
                     className='form-input rounded border p-2 mt-3 w-full'
                     onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='flex pt-5 justify-end'>
              <HCaptcha
                size='normal'
                theme='light'
                sitekey={hCaptchaSiteKey}
                onVerify={setHCaptchaToken}
                ref={captchaRef}
              />
            </div>
            <div className='flex justify-end'>
              <input type='submit' value='Submit'
                     onClick={onSubmit}
                     className='cursor-pointer w-3/6 mt-6 bg-blue-600 hover:bg-blue-500 text-white font-semibold p-3 ' />
            </div>
          </div>

        </form>
        {token
          ? (
            <div className='container justify-center px-2 pt-0 md:px-0 mx-auto'>
              <p className='text-3xl text-gray-600'>Waiting your submission....</p>
              <div
                className='relative overflow-hidden shadow-xl flex bg-gray-800 h-[31.625rem] max-h-[60vh] sm:max-h-[none] sm:rounded-md lg:h-[34.6875rem] xl:h-[31.625rem] !h-auto max-h-[none]'>
                <div className='relative w-full flex flex-col'>
                  <div className='absolute top-px right-px z-30'>
                    <CopyToClipboard text={token} onCopy={onCopy}>
                      <svg className='h-10 w-10 stroke-white hover:stroke-gray-500' width='24' height='24'
                           viewBox='0 0 24 24' strokeWidth='2'
                           fill='none' strokeLinecap='round' strokeLinejoin='round'>
                        <path stroke='none' d='M0 0h24v24H0z' />
                        <rect x='8' y='8' width='12' height='12' rx='2' />
                        <path d='M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2' />
                      </svg>
                    </CopyToClipboard>
                  </div>
                  <code className='flex-auto relative block text-gray-50 pt-4 pb-4 px-10 overflow-auto break-words'>
                    {token}
                  </code>
                </div>
              </div>
              {copied ? (<span className='text-red-600'>Copied!</span>) : null}
            </div>
          )
          : null}

      </section>

      <section className='text-gray-700 body-font'>
        <div className='container flex flex-col items-center px-8 py-8 mx-auto max-w-7xl sm:flex-row'>
          <p className='mt-4 text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0'>Salzify ©
            2021</p>

          <span className='inline-flex justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start'>
            <a href='https://www.linkedin.com/company/salzify' target='_blank'
               className='text-gray-400 hover:text-gray-500'>
              <span className='sr-only'>LinkedIn</span>
              <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'>  <path
                d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z' />
              <rect x='2' y='9' width='4' height='12' />  <circle cx='4' cy='4' r='2' />
            </svg>
            </a>

            {/* <a href='#' className='text-gray-400 hover:text-gray-500'> */}
            {/*  <span className='sr-only'>Twitter</span> */}
            {/*  <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'> */}
            {/*    <path */}
            {/*      d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' */}
            {/*    /> */}
            {/*  </svg> */}
            {/* </a> */}

            <a href='https://github.com/salzify' target='_blank' className='text-gray-400 hover:text-gray-500'>
              <span className='sr-only'>GitHub</span>
              <svg className='w-6 h-6' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'>
                <path
                  fillRule='evenodd'
                  d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
                  clipRule='evenodd'
                />
              </svg>
            </a>

          </span>
        </div>
      </section>
    </main>
  )
}

export default JoinUsPage
